import React from "react"
import { RichText } from "prismic-reactjs"
import { linkResolver } from "../utils/linkResolver"
import { graphql } from "gatsby"
import {
  CTABanner,
  FeaturedItems,
  NumberedItems,
  Separator,
  TextBlock,
} from "../components/slices"

import Layout from "../components/layouts"

export const query = graphql`
  {
    prismic {
      allHomes(uid: null) {
        edges {
          node {
            _meta {
              uid
              id
              type
            }
            title
            banner_image
            banner_images {
              image
            }
            banner_text
            body {
              __typename
              ... on PRISMIC_HomeBodyFeatured_items {
                type
                primary {
                  section_title
                  button_label
                  button_link {
                    __typename
                    ... on PRISMIC_Products {
                      title
                      _meta {
                        uid
                        id
                        lang
                        type
                        tags
                      }
                    }
                  }
                }
                fields {
                  link_to_product {
                    __typename
                    _linkType
                    ... on PRISMIC_Product {
                      product_name
                      product_image
                      sub_title
                      shadow_color
                      _meta {
                        uid
                        id
                        lang
                        type
                        tags
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

const RenderSlices = ({ slices }) => {
  return slices.map((slice, index) => {
    const res = (() => {
      switch (slice.type) {
        case "cta_banner":
          return (
            <div key={index} className="homepage-slice-wrapper">
              <CTABanner slice={slice} />
            </div>
          )

        case "featured_items":
          return (
            <div key={index} className="homepage-slice-wrapper">
              <FeaturedItems slice={slice} />
            </div>
          )

        case "big_bullet_item":
          return (
            <div key={index} className="homepage-slice-wrapper">
              <NumberedItems slice={slice} />
            </div>
          )

        case "separator":
          return (
            <div key={index} className="homepage-slice-wrapper">
              <Separator />
            </div>
          )

        case "text_block":
          return (
            <div key={index} className="homepage-slice-wrapper">
              <TextBlock slice={slice} />
            </div>
          )

        default:
          return
      }
    })()
    return res
  })
}

const RenderBody = ({ home }) => {
  const bannerImages = home.banner_images
  const bannerImagesLength = bannerImages.length

  let count = 0
  const updateImage = e => {
    count = (count + 1) % bannerImagesLength
    e.target.src = bannerImages[count].image.url
  }

  return (
    <React.Fragment>
      <section className="homepage-banner">
        <img
          className="homepage-banner-image"
          src={home.banner_image.url}
          alt={home.banner_image.alt}
          onClick={e => updateImage(e)}
        />
        <div className="homepage-banner-box-wrapper">
          <div className="homepage-banner-box">
            {RichText.render(home.banner_text, linkResolver)}
          </div>
        </div>
      </section>

      <div className="homepage-slices-wrapper">
        <RenderSlices slices={home.body} />
      </div>

      {/* <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 100 125"
        fill="#ffffff"
        width="82"
      >
        <path d="M50 11c21.505 0 39 17.495 39 39S71.505 89 50 89 11 71.505 11 50s17.495-39 39-39m0-6C25.147 5 5 25.147 5 50s20.147 45 45 45 45-20.147 45-45S74.853 5 50 5z" />
        <path d="M57.93 66.827c0 .811-1.913 2.711-5.738 5.7-3.826 2.989-7.283 4.484-10.374 4.484-2.128 0-3.673-.545-4.636-1.634-.963-1.089-1.444-2.318-1.444-3.686 0-.861.126-1.659.38-2.394a49.31 49.31 0 0 1 .836-2.242l7.22-14.972c.405-.861.697-1.558.874-2.09.177-.532.266-1 .266-1.406 0-.405-.076-.696-.228-.874a.762.762 0 0 0-.608-.266c-.659 0-1.938 1.001-3.838 3.002-1.9 2.001-3.028 3.002-3.382 3.002-.405 0-.811-.279-1.216-.836-.406-.557-.608-.912-.608-1.064 0-.507.481-1.33 1.444-2.47.962-1.14 2.179-2.343 3.648-3.61 1.317-1.165 2.9-2.254 4.75-3.268 1.849-1.013 3.483-1.52 4.902-1.52 2.28 0 3.99.646 5.13 1.938 1.14 1.292 1.71 2.85 1.71 4.674 0 .76-.076 1.571-.228 2.432-.152.861-.481 1.748-.988 2.66l-5.776 12.692c-.456 1.064-.786 1.925-.988 2.584-.203.659-.304 1.115-.304 1.368 0 .304.076.494.228.57s.304.114.456.114c1.114 0 2.419-.798 3.914-2.394s2.444-2.394 2.85-2.394c.354 0 .734.266 1.14.798.405.532.608.9.608 1.102zM63.782 27.611c0 1.875-.671 3.496-2.014 4.864-1.343 1.368-2.977 2.052-4.902 2.052-1.875 0-3.496-.684-4.864-2.052-1.368-1.368-2.052-2.989-2.052-4.864s.684-3.496 2.052-4.864 2.989-2.052 4.864-2.052c1.925 0 3.559.684 4.902 2.052 1.343 1.369 2.014 2.99 2.014 4.864z" />
      </svg> */}
    </React.Fragment>
  )
}

export default ({ data }) => {
  // Required check for no data being returned
  const doc = data.prismic.allHomes.edges.slice(0, 1).pop()
  if (!doc) return null

  return (
    <Layout>
      <RenderBody home={doc.node} />
    </Layout>
  )
}
